import { ClientService, RequestMethod } from '../client-service'
import { RequestResponseLogic } from '../Ui/request-response'

const HEALTH_PLAN = '/health_goal'

export const HealthPlanLogic = {
  async getHealthPlanList(type, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      HEALTH_PLAN + '/' + type,
      params
    )
    return await RequestResponseLogic.checkRequestResponse(result)
  },
  async setHealthPlan(type, data, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      HEALTH_PLAN + '/' + type,
      params,
      data
    )
    return await RequestResponseLogic.checkRequestResponse(result)
  },
  async deleteHealthPlan(type, planId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      HEALTH_PLAN + '/' + type + '/' + planId,
      params
    )
    return await RequestResponseLogic.checkRequestResponse(result)
  },
}
